
import { Options } from "vue-class-component";
import PageBase from "@/shared/components/common/PageBase";
import HeaderMessage from "@/shared/components/common/HeaderMessage.vue";
import { Global } from "@/support/GlobalData";
import App from "@/App.vue";
import { CameraImageDto } from "@/shared/models/CameraImageDto";
import { DataRequest } from "@/shared/support/Data";
import { confirmOk } from "@/shared/components/common/AlertDialog.vue";
import Utility from "@/shared/support/Utility";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import { UploadType } from "@/shared/enums/UploadType";
import UploadPanel from "@/shared/components/common/UploadPanel.vue";
import Globals from "@/support/Globals";

@Options({
    HeaderMessage,
    UploadPanel,
})

export default class CameraImage extends PageBase {

    get cameraImage(): string {
        return this.cameraImageCache;
    }
    set cameraImage(image: string) {
        this.cameraImageCache = image;
    }
    cameraImageCache = "";

    vehicle(): string {
        return `${Global.Year} ${Global.Make} ${Global.Model}`;
    }

    getUploadType(): number {
        return UploadType.VehicleDocument;
    }
    getVehicleId(): string {
        return Global.VehicleId?.toString() || "";
    }

    loadCameraImage(): void {

        this.loading = true;

        const request = new DataRequest();
        request.$post<any, CameraImageDto>("/Service/CameraParser/GetCameraImageInfo", { CalibrationSessionGuid: Global.CalibrationSessionGuid })
            .then((cameraImage: CameraImageDto): void => {
                if (!cameraImage.Url) {
                    this.loading = false;
                    confirmOk("An unexpected error occurred retrieving the camera image");
                    return;
                }
                const url = Utility.formatUrl(cameraImage.Url, {}, Globals.GetWebApi(), true);
                this.loading = true; // we'll get an onload event (cameraImageLoaded) which clears the loading indicator
                this.cameraImage = url;
            })
            .catch((reason): void => {
                this.loading = false;
                confirmOk(`An unexpected error occurred retrieving the camera image - ${reason}`);
            });
    }

    cameraImageLoaded(): void {
        this.loading = false;
    }

    cancelClicked(): void {
        App.MainApp.cancelCalibration();
    }

    recalibrateClicked(): void {
        CalibrationEventSupport.sendEvent(CalibrationEvent.RestartCalibration);
        this.loading = true;
    }

    sendFirstImageClicked(): void {
        CalibrationEventSupport.sendEvent(CalibrationEvent.SendTargetByCompanion);
        this.loading = true;
    }

    mounted(): void {
        this.loading = true;
        App.MainApp.connectSession((): void => {
            this.loadCameraImage();
        });
    }
}

