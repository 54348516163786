
import { Options } from "vue-class-component";
import PageBase from "@/shared/components/common/PageBase";
import HeaderMessage from "@/shared/components/common/HeaderMessage.vue";
import App from "@/App.vue";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";

@Options({
    components: {
        HeaderMessage,
    }
})
export default class MeasuredWindshieldAngle extends PageBase {

    angle = 0;

    get angleText(): string {
        if (!this.angle) return "";
        return this.angle.toFixed(1);
    }

    yesClicked(): void {
        this.loading = true;
        CalibrationEventSupport.sendEventMeasurementCorrect(CalibrationEvent.MeasuredWindshieldAngleCorrectByCompanion, true);
    }
    noClicked(): void {
        this.loading = true;
        CalibrationEventSupport.sendEventMeasurementCorrect(CalibrationEvent.MeasuredWindshieldAngleCorrectByCompanion, false);
    }

    mounted(): void {

        const q = this.$router.currentRoute.value.query;
        this.angle = Number(q.WindshieldAngle);

        this.loading = true;
        App.MainApp.connectSession((): void => {
            this.loading = false;
        });
    }
}

