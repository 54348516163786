
import { Options } from "vue-class-component";
import PageBase from "@/shared/components/common/PageBase";
import HeaderMessage from "@/shared/components/common/HeaderMessage.vue";
import { Global } from "@/support/GlobalData";
import App from "@/App.vue";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import { MarkerType } from "@/shared/enums/MarkerType";

@Options({
    components: {
        HeaderMessage,
    },
})
export default class CameraSetup extends PageBase {

    isTechControlled(): boolean {
        return Global.IsTechControlled!;
    }
    legA(): number {
        return Global.LegLengthA;
    }
    legB(): number {
        return Global.LegLengthB;
    }
    legC(): number {
        return Global.LegLengthC;
    }
    get standardMarker(): boolean { return Global.Marker === MarkerType.Standard; }
    get highAngleMarker(): boolean { return Global.Marker === MarkerType.HighAngle; }

    cancelClicked(): void {
        App.MainApp.cancelCalibration();
    }
    nextClicked(): void {
        CalibrationEventSupport.sendEvent(CalibrationEvent.GetCameraAlignmentImage);
        this.loading = true;
    }

    mounted(): void {
        this.loading = true;
        App.MainApp.connectSession((): void => {
            this.loading = false;
        });
    }
}

