import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")
  const _component_router_view = _resolveComponent("router-view")
  const _component_Footer = _resolveComponent("Footer")
  const _component_alert_dialog = _resolveComponent("alert-dialog")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_Header, { ref: "header" }, null, 512),
    _createVNode(_component_router_view),
    _createVNode(_component_Footer),
    _createVNode(_component_alert_dialog, { ref: "alertDialog" }, null, 512)
  ]))
}