

import { Options, Vue } from "vue-class-component";

import { SignalR } from "./support/SignalR";
import Tooltip from "@/shared/support/Tooltip";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { ButtonClicked } from "@/shared/enums/ButtonClicked";
import AlertDialog, { confirmYesNo } from "@/shared/components/common/AlertDialog.vue";
import { Global } from "./support/GlobalData";
import { CalibrationEventSupport } from "./support/CalibrationEvent";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import { DataRequest } from "@/shared/support/Data";
import Globals from "./support/Globals";

@Options({
    components: {
        Header,
        Footer,
        AlertDialog
    }
})
export default class App extends Vue {

    public created(): void {
        Global.MainApp = this;
    }
    mounted(): void {
        AlertDialog.initializeAlertDialog(this.$refs.alertDialog);
        Tooltip.initializeTooltips();

        // Init signalr
        SignalR.start(Globals.GetSignalRFunction());

        DataRequest.getWebAPI = (): string => {
            return Globals.GetWebApi();
        };
        DataRequest.setRequestHeaders = (request: XMLHttpRequest, useAuthentication: boolean): void => {
            request.setRequestHeader("Companion-Api-Version", "v1.0");
            if (DataRequest.haveToken && DataRequest.getToken && DataRequest.haveToken())
                request.setRequestHeader(process.env.VUE_APP_COMPANION_HEADER!, DataRequest.getToken());
        };
        DataRequest.getToken = (): string => {
            return btoa(`${Global.CalibrationSessionGuid};${Global.CompanionGuid}`);
        };
        DataRequest.haveToken = (): boolean => {
            return !!Global.CalibrationSessionGuid && !!Global.CompanionGuid;
        };
        DataRequest.getMaxRetries = (): number => {
            return 3;
        };
    }

    // Session Management

    public connectSession(action: () => void): void {
        const q = App.MainApp.$router.currentRoute.value.query;
        const connection = q.c as string;
        const calibrationSessionGuid = q.s as string;
        if (!connection || !calibrationSessionGuid) {
            Global.MainApp.routerPush("/NoConnection");
            return;
        }
        Global.CompanionGuid = connection;
        Global.CalibrationSessionGuid = calibrationSessionGuid;

        if (!SignalR.isCompanionStarted()) {
            this.startCompanionSession(action);
        } else {
            action();
        }
    }

    startCompanionSession(action: () => void): void {
        // wait for signalR and companion session to start
        setTimeout((): void => {
            if (!SignalR.startCompanionSession()) {
                this.startCompanionSession(action);
            } else {
                action();
            }
        }, 100);
    }

    // Main App accessors

    public static get MainApp(): App {
        return Global.MainApp;
    }
    public updateHeader(): void {
        this.$refs.header.update();
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public routerPush(url: string, query?:any): void {
        let complete = `${url}?c=${Global.CompanionGuid}&s=${Global.CalibrationSessionGuid}&x=${this.ticks()}`;

        if (query) {
            let args = "";
            for (const key in query) {
                args += "&";
                const val = query[key];
                if (val !== undefined && val !== null)
                    args += `${encodeURIComponent(key)}=${encodeURIComponent(val)}`;
                else
                    args += `${encodeURIComponent(key)}=`;
            }
            complete += args;
        }
        this.$router.replace(complete);
    }
    ticks(): string {
        const dNow = new Date();
        const dStart = new Date(1970, 0, 1);
        const seconds = dNow.getTime();
        const secondsStart = dStart.getTime();
        const dateDifference = seconds - secondsStart;
        return dateDifference.toString();
    }

    public cancelCalibration(): void {
        confirmYesNo("Are you sure you want to cancel this calibration?")
            .then((clicked: ButtonClicked) => {
                if (clicked === ButtonClicked.Ok) {
                    CalibrationEventSupport.sendEvent(CalibrationEvent.CalibrationCompanionCanceled);
                    App.MainApp.routerPush("/Canceled");
                }
            });
    }

    $refs!: {
        alertDialog: AlertDialog
        header: Header,
    }
}

