
import { Options } from "vue-class-component";
import PageBase from "@/shared/components/common/PageBase";
import HeaderMessage from "@/shared/components/common/HeaderMessage.vue";
import { Global } from "@/support/GlobalData";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import App from "@/App.vue";

@Options({
    HeaderMessage
})
export default class Vin extends PageBase {

    vin: string = "";

    isTechControlled(): boolean {
        return Global.IsTechControlled!;
    }
    get isValid(): boolean {
        return this.vin.match(/^[0-9]{5}$/) != null;
    }

    cancelClicked(): void {
        App.MainApp.cancelCalibration();
    }

    nextClicked(): void {
        CalibrationEventSupport.sendEventVin(CalibrationEvent.VinSelectionByCompanion, this.vin);
        this.loading = true;
    }

    mounted(): void {
        this.loading = true;
        App.MainApp.connectSession((): void => {
            this.loading = false;
        });
    }
}
