
import { Options } from "vue-class-component";
import PageBase from "@/shared/components/common/PageBase";
import HeaderMessage from "@/shared/components/common/HeaderMessage.vue";
import App from "@/App.vue";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import { Global } from "@/support/GlobalData";
import { CalibrationType } from "@/shared/enums/CalibrationType";

@Options({
    HeaderMessage,
    // UploadPanel,
})

export default class Completed extends PageBase {

    get message(): string {
        if (Global.Calibration === CalibrationType.Sequential)
            return "Targets have been successfully displayed.";
        else
            return "Target has been successfully displayed.";
    }

    achnowledgeClicked(): void {
        CalibrationEventSupport.sendEvent(CalibrationEvent.AcknowledgeByCompanion);
    }

    mounted(): void {
        this.loading = true;
        App.MainApp.connectSession((): void => {
            this.loading = false;
        });
    }
}

