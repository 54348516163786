
import { Options } from "vue-class-component";
import PageBase from "@/shared/components/common/PageBase";
import HeaderMessage from "@/shared/components/common/HeaderMessage.vue";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import App from "@/App.vue";

@Options({
    components: {
        HeaderMessage
    }
})
export default class NewWindshieldAngle extends PageBase {

    angleText = "";

    get isValid(): boolean {
        return this.angleText.match(/^[1-9][0-9]{0,1}(\.[0-9]){0,1}$/) != null;
    }

    nextClicked(): void {
        this.loading = true;
        if (!this.isValid) return;
        const angle = Number(this.angleText);
        CalibrationEventSupport.sendEventNewWindshieldAngle(CalibrationEvent.WindshieldAngleSelectionByCompanion, angle);
    }

    cancelClicked(): void {
        App.MainApp.cancelCalibration();
    }

    mounted(): void {
        this.loading = true;
        App.MainApp.connectSession((): void => {
            this.loading = false;
        });
    }
}
