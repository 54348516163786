

import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

export default class Help extends Vue {
    @Prop({ default: "left" }) readonly horizontal: string = "left";

    url = "https://airprodiagnostics.com/auggie-frequently-asked-questions/";
}

