export class Globals {

    public static GetEnvironment(): string {
        const host = window.location.hostname;
        if (host.startsWith("companion-qat.")) {
            return "Quality Assurance Testing Environment";
        } else if (host.startsWith("companion-uat.")) {
            return "User Acceptance Testing Environment";
        } else if (host.startsWith("companion-stg.")) {
            return "";
        } else if (host.startsWith("companion.")) {
            return "";
        } else if (host === "localhost") {
            if (window.location.port === "44343")
                return "Development Environment";
        }
        throw new Error(`Unexpected host ${host} can't be mapped to environment`);
    }

    public static GetSignalRFunction(): string {
        const host = window.location.hostname;
        if (host.startsWith("companion-qat.")) {
            return "https://api-qat.auggie.tech/signalr";
        } else if (host.startsWith("companion-uat.")) {
            return "https://api-uat.auggie.tech/signalr";
        } else if (host.startsWith("companion-stg.")) {
            return "https://api-stg.auggie.tech/signalr";
        } else if (host.startsWith("companion.")) {
            return "https://api.auggie.tech/signalr";
        } else if (host === "localhost") {
            if (window.location.port === "44343")
                return "http://localhost:7172/api";
        }
        throw new Error(`Unexpected host ${host} can't be mapped to SignalR Function`);
    }

    public static GetWebApi(): string {
        const host = window.location.hostname;
        if (host.startsWith("companion-qat.")) {
            return "https://api-qat.auggie.tech/companion/";
        } else if (host.startsWith("companion-uat.")) {
            return "https://api-uat.auggie.tech/companion/";
        } else if (host.startsWith("companion-stg.")) {
            return "https://api-stg.auggie.tech/companion/";
        } else if (host.startsWith("companion.")) {
            return "https://api.auggie.tech/companion/";
        } else if (host === "localhost") {
            if (window.location.port === "44343")
                return "https://localhost:44360/";
        }
        throw new Error(`Unexpected host ${host} can't be mapped to API`);
    }
}
export default Globals;
