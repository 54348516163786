
import { Options } from "vue-class-component";
import PageBase from "@/shared/components/common/PageBase";
import HeaderMessage from "@/shared/components/common/HeaderMessage.vue";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import App from "@/App.vue";
import { Global } from "@/support/GlobalData";
import { confirmOk } from "@/shared/components/common/AlertDialog.vue";

@Options({
    HeaderMessage
})
export default class RideHeight extends PageBase {

    frontRight = "";
    frontLeft = "";
    rearRight = "";
    rearLeft = "";

    isValid(): boolean {
        if (!this.frontLeft.length || !this.isValidEntry(this.frontLeft) || isNaN(Number(this.frontLeft))) return false;
        if (!this.frontRight.length || !this.isValidEntry(this.frontRight) || isNaN(Number(this.frontRight))) return false;
        if (!this.rearLeft.length || !this.isValidEntry(this.rearLeft) || isNaN(Number(this.rearLeft))) return false;
        if (!this.rearRight.length || !this.isValidEntry(this.rearRight) || isNaN(Number(this.rearRight))) return false;
        return true;
    }
    isValidHeight(height: number): boolean {
        if (!Global.WheelArchHeightMin || !Global.WheelArchHeightMax) return true;
        return Global.WheelArchHeightMin < height && height <= Global.WheelArchHeightMax;
    }
    isValidEntry(val: string): boolean {
        return val.match(/^[0-9]{1,4}$/) != null;
    }

    cancelClicked(): void {
        App.MainApp.cancelCalibration();
    }

    nextClicked(): void {
        const fl = Number(this.frontLeft);
        const fr = Number(this.frontRight);
        const rl = Number(this.rearLeft);
        const rr = Number(this.rearRight);
        if (!this.isValidHeight(fl) || !this.isValidHeight(fr) || !this.isValidHeight(rl) || !this.isValidHeight(rr)) {
            confirmOk("Measurement(s) outside normal range for this vehicle. Verify measurements (see picture below) are accurate and entered in millimeters. Contact Auggie support for assistance.");
            return;
        }
        CalibrationEventSupport.sendEventRideHeight(CalibrationEvent.RideHeightEntryByCompanion, Number(this.frontLeft), Number(this.frontRight), Number(this.rearLeft), Number(this.rearRight));
        this.loading = true;
    }

    mounted(): void {
        this.loading = true;
        App.MainApp.connectSession((): void => {
            this.loading = false;
        });
    }
}
