
import { Options } from "vue-class-component";
import PageBase from "@/shared/components/common/PageBase";
import HeaderMessage from "@/shared/components/common/HeaderMessage.vue";
import App from "@/App.vue";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";

@Options({
    HeaderMessage,
    // UploadPanel,
})

export default class Failed extends PageBase {

    achnowledgeClicked(): void {
        CalibrationEventSupport.sendEvent(CalibrationEvent.AcknowledgeByCompanion);
    }

    mounted(): void {
        this.loading = true;
        App.MainApp.connectSession((): void => {
            this.loading = false;
        });
    }
}

