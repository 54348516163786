
import { Options } from "vue-class-component";
import PageBase from "@/shared/components/common/PageBase";
import HeaderMessage from "@/shared/components/common/HeaderMessage.vue";
import App from "@/App.vue";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";

@Options({
    components: {
        HeaderMessage,
    }
})
export default class ContactSupportWindshieldAngle extends PageBase {

    okClicked(): void {
        this.loading = true;
        CalibrationEventSupport.sendEvent(CalibrationEvent.ContactSupportWindshieldAngleAcknowledgeByCompanion);
    }

    mounted(): void {
        this.loading = true;
        App.MainApp.connectSession((): void => {
            this.loading = false;
        });
    }
}

