

import { Global } from "@/support/GlobalData";
import Globals from "@/support/Globals";
import { Options, Vue } from "vue-class-component";

@Options({
})
export default class Header extends Vue {

    deviceName(): string {
        return Global.DeviceName;
    }
    environmentName = Globals.GetEnvironment();

    public update(): void {
        this.$forceUpdate();
    }
}
