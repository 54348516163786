
import { Options } from "vue-class-component";
import PageBase from "@/shared/components/common/PageBase";
import HeaderMessage from "@/shared/components/common/HeaderMessage.vue";
import App from "@/App.vue";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { SignalR } from "@/support/SignalR";
import Toast from "@/shared/support/Toast";

@Options({
    HeaderMessage
})

export default class CameraReady extends PageBase {

    cancelClicked(): void {
        App.MainApp.cancelCalibration();
    }
    nextClicked(): void {
        CalibrationEventSupport.sendEvent(CalibrationEvent.CameraVerificationByCompanion);
        this.loading = true;
    }
    deviceCameraFailed(): void {
        this.loading = false;
        Toast.error("Please align the camera so Yaw & Roll Values are within +/- 1 degrees");
    }

    mounted(): void {
        SignalR.onDeviceCameraFailed = this.deviceCameraFailed;
        this.loading = true;
        App.MainApp.connectSession((): void => {
            this.loading = false;
        });
    }
    unmounted(): void {
        SignalR.onDeviceCameraFailed = null;
    }
}

