
import { Options } from "vue-class-component";
import PageBase from "@/shared/components/common/PageBase";
import HeaderMessage from "@/shared/components/common/HeaderMessage.vue";
import App from "@/App.vue";
import Toast from "@/shared/support/Toast";
import { SignalR } from "@/support/SignalR";

@Options({
    components: {
        HeaderMessage
    }
})
export default class InProgress extends PageBase {

    cancelClicked(): void {
        App.MainApp.cancelCalibration();
    }

    mounted(): void {
        this.loading = true;
        App.MainApp.connectSession((): void => {
            this.loading = false;
            SignalR.onVehicleTargetImageReady = (targetImageUploadId: number): void => {
                Toast.info("New target image received");
            };
            SignalR.onTechnicianCameraRejected = (): void => {
                Toast.info("The technician requested repositioning and verifying the camera.");
            };
        });
    }
    unmounted(): void {
        SignalR.onVehicleTargetImageReady = null;
        SignalR.onTechnicianCameraRejected = null;
    }
}

