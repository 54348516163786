
import { Options } from "vue-class-component";
import PageBase from "@/shared/components/common/PageBase";
import HeaderMessage from "@/shared/components/common/HeaderMessage.vue";
import App from "@/App.vue";

@Options({
    HeaderMessage
})

export default class Contacting extends PageBase {

    cancelClicked(): void {
        App.MainApp.cancelCalibration();
    }

    mounted(): void {
        this.loading = true;
        App.MainApp.connectSession((): void => {
            this.loading = false;
        });
    }
}

