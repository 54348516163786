
import { Options } from "vue-class-component";
import PageBase from "@/shared/components/common/PageBase";
import Loading from "@/shared/components/common/Loading.vue";
import App from "@/App.vue";

@Options({
    Loading,
})
export default class Home extends PageBase {

    mounted(): void {
        this.loading = true;
        App.MainApp.connectSession((): void => {
            // no action
        });
    }
}

