import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import { CalibrationType } from "@/shared/enums/CalibrationType";
import CalibrationEventDto from "@/shared/models/CalibrationEventDto";
import { DataRequest } from "../shared/support/Data";
import { Global } from "./GlobalData";

export class CalibrationEventSupport {

    public static readonly MaxRetries = 3;

    public static sendEvent(name: CalibrationEvent): Promise<CalibrationEventDto> {
        const dr = new DataRequest(undefined, undefined, CalibrationEventSupport.MaxRetries);
        const calibrationEvent = { CalibrationSessionGuid: Global.CalibrationSessionGuid, CalibrationEvent: name } as CalibrationEventDto;
        return dr.$post<CalibrationEventDto>("/Service/CalibrationEvent", null, calibrationEvent);
    }
    public static sendEventVehicleSelection(name: CalibrationEvent,
        year: number, make: string, model: string, calType: CalibrationType): Promise<CalibrationEventDto> {

        const dr = new DataRequest(undefined, undefined, CalibrationEventSupport.MaxRetries);
        const calibrationEvent = {
            CalibrationSessionGuid: Global.CalibrationSessionGuid,
            CalibrationEvent: name,
            VehicleYear: year,
            VehicleMake: make,
            VehicleModel: model,
            CalibrationType: calType
        } as CalibrationEventDto;
        return dr.$post<CalibrationEventDto>("/Service/CalibrationEvent", null, calibrationEvent);
    }
    public static sendEventVin(name: CalibrationEvent, vin: string): Promise<CalibrationEventDto> {
        const dr = new DataRequest(undefined, undefined, CalibrationEventSupport.MaxRetries);
        const calibrationEvent = {
            CalibrationSessionGuid: Global.CalibrationSessionGuid,
            CalibrationEvent: name,
            Vin: vin,
        } as CalibrationEventDto;
        return dr.$post<CalibrationEventDto>("/Service/CalibrationEvent", null, calibrationEvent);
    }
    public static sendEventRideHeight(name: CalibrationEvent, frontLeft: number, frontRight: number, rearLeft: number, rearRight: number): Promise<CalibrationEventDto> {
        const dr = new DataRequest(undefined, undefined, CalibrationEventSupport.MaxRetries);
        const calibrationEvent = {
            CalibrationSessionGuid: Global.CalibrationSessionGuid,
            CalibrationEvent: name,
            RideHeightFrontLeft: frontLeft,
            RideHeightFrontRight: frontRight,
            RideHeightRearLeft: rearLeft,
            RideHeightRearRight: rearRight
        } as CalibrationEventDto;
        return dr.$post<CalibrationEventDto>("/Service/CalibrationEvent", null, calibrationEvent);
    }
    public static sendEventLightIntensity(name: CalibrationEvent, intensity: number): Promise<CalibrationEventDto> {
        const dr = new DataRequest(undefined, undefined, CalibrationEventSupport.MaxRetries);
        const calibrationEvent = { CalibrationSessionGuid: Global.CalibrationSessionGuid, CalibrationEvent: name, LightIntensity: intensity } as CalibrationEventDto;
        return dr.$post<CalibrationEventDto>("/Service/CalibrationEvent", null, calibrationEvent);
    }
    public static sendEventMeasurementCorrect(name: CalibrationEvent, correct: boolean): Promise<CalibrationEventDto> {
        const dr = new DataRequest(undefined, undefined, CalibrationEventSupport.MaxRetries);
        const calibrationEvent = {
            CalibrationSessionGuid: Global.CalibrationSessionGuid,
            CalibrationEvent: name,
            WindshieldAngleCorrect: correct,
        } as CalibrationEventDto;
        return dr.$post<CalibrationEventDto>("/Service/CalibrationEvent", null, calibrationEvent);
    }
    public static sendEventNewWindshieldAngle(name: CalibrationEvent, angle: number): Promise<CalibrationEventDto> {
        const dr = new DataRequest(undefined, undefined, CalibrationEventSupport.MaxRetries);
        const calibrationEvent = {
            CalibrationSessionGuid: Global.CalibrationSessionGuid,
            CalibrationEvent: name,
            WindshieldOverride: angle,
        } as CalibrationEventDto;
        return dr.$post<CalibrationEventDto>("/Service/CalibrationEvent", null, calibrationEvent);
    }
}