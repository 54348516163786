
import { Options } from "vue-class-component";
import PageBase from "@/shared/components/common/PageBase";
import HeaderMessage from "@/shared/components/common/HeaderMessage.vue";
import SelectionSupport, { SelectionEntry } from "@/shared/support/Selection";
import { CalibrationType, CalibrationTypeDescription, CalibrationTypeSpecific } from "@/shared/enums/CalibrationType";
import { VehicleDto } from "@/shared/models/VehicleDto";
import { DataRequest } from "@/shared/support/Data";
import { Global } from "@/support/GlobalData";
import { VehicleCalibrationDto } from "@/shared/models/VehicleCalibrationDto";
import { confirmOk } from "@/shared/components/common/AlertDialog.vue";
import { GenericActiveStatus } from "@/shared/enums/GenericActiveStatus";
import { VehicleFilterType } from "@/shared/enums/VehicleFilterType";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import App from "@/App.vue";

@Options({
    HeaderMessage
})

export default class Vehicle extends PageBase {

    selectedYear: number|string = "";
    yearSelections: any = null;
    selectedMake: string = "";
    makeSelections: any = null;
    selectedModel: string = "";
    modelSelections: any = null;
    selectedCalibration: CalibrationType|string = "";
    calibrationSelections: SelectionEntry[] = SelectionSupport.EnumSelections(CalibrationTypeSpecific, CalibrationTypeDescription);
    multipleCalibrations = false;
    vehicle: VehicleDto|null = null;

    isTechControlled(): boolean {
        return Global.IsTechControlled!;
    }

    makeChange():void {
        this.updateDropDownModel();
    }
    modelChange():void {
        this.updateDropDownYear();
    }
    yearChange():void {
        this.updateCalibration();
    }
    calibrationChange():void {
        // nothing to do
    }

    updateDropDownMake(make?: string, model?: string, year?: number, calibration?: CalibrationType): void {

        this.loading = true;

        const dr = new DataRequest();
        dr.$get<SelectionEntry[]>("/Service/Vehicle/DisplayList", {
            "VehicleFilterType": VehicleFilterType.VehicleMake,
            CalibrationSessionGuid: Global.CalibrationSessionGuid
        })
            .then((options:SelectionEntry[]): void => {
                this.makeSelections = options;
                this.selectedMake = make || "";
                this.modelSelections = [];
                this.selectedModel = "";
                this.yearSelections = [];
                this.selectedYear = "";
                this.selectedCalibration = "";
                this.multipleCalibrations = false;
                this.vehicle = null;
                this.loading = false;
                if (make && model)
                    this.updateDropDownModel(model, year, calibration);
            }).catch((e): void => {
                this.loading = false;
            });
    }
    updateDropDownModel(model?: string, year?: number, calibration?: CalibrationType): void {

        if (!this.selectedMake) {
            this.selectedModel = "";
            this.modelSelections = [];
            this.selectedYear = "";
            this.yearSelections = [];
            this.selectedCalibration = "";
            this.multipleCalibrations = false;
            return;
        }

        this.loading = true;

        const dr = new DataRequest();
        dr.$get<SelectionEntry[]>("/Service/Vehicle/DisplayList", {
            "VehicleFilterType": VehicleFilterType.VehicleModel,
            VehicleMake: this.selectedMake,
            CalibrationSessionGuid: Global.CalibrationSessionGuid
        })
            .then((options:SelectionEntry[]): void => {
                this.modelSelections = options;
                this.selectedModel = model || "";
                this.yearSelections = [];
                this.selectedYear = "";
                this.selectedCalibration = "";
                this.multipleCalibrations = false;
                this.vehicle = null;
                this.loading = false;
                if (model && year)
                    this.updateDropDownYear(year, calibration);
            }).catch((e): void => {
                this.loading = false;
            });
    }
    updateDropDownYear(year?: number, calibration?: CalibrationType): void {

        if (!this.selectedModel) {
            this.selectedYear = "";
            this.yearSelections = [];
            this.selectedCalibration = "";
            this.multipleCalibrations = false;
            return;
        }

        this.loading = true;

        const dr = new DataRequest();
        dr.$get<SelectionEntry[]>("/Service/Vehicle/DisplayList", {
            "VehicleFilterType": VehicleFilterType.VehicleYear,
            VehicleMake: this.selectedMake,
            VehicleModel: this.selectedModel,
            CalibrationSessionGuid: Global.CalibrationSessionGuid
        })
            .then((options:SelectionEntry[]): void => {
                this.yearSelections = options;
                this.selectedYear = year || "";
                this.selectedCalibration = "";
                this.multipleCalibrations = false;
                this.vehicle = null;
                this.loading = false;
                if (calibration)
                    this.updateCalibration(calibration);
            }).catch((e): void => {
                this.loading = false;
            });
    }
    updateCalibration(calibration?: CalibrationType): void {
        this.selectedCalibration = calibration || "";
        this.multipleCalibrations = false;
        if (!this.selectedYear)
            return;

        this.getCalibrationTypes();
    }

    reloadDropdowns(): void {
        if (Global.Make) {
            this.updateDropDownMake(Global.Make, Global.Model, Global.Year, Global.Calibration);
        } else {
            this.updateDropDownMake();
        }
    }

    getCalibrationTypes(): void {

        this.loading = true;

        const dr = new DataRequest();
        dr.autoToastOnFailure = false;
        dr.$get<VehicleDto[]>("/Service/Vehicle/All", {
            VehicleYear: this.selectedYear,
            VehicleMake: this.selectedMake,
            VehicleModel: this.selectedModel,
            CalibrationSessionGuid: Global.CalibrationSessionGuid,
            StatusFilter: GenericActiveStatus.Active,
        })
            .then((availableVehicles: VehicleDto[]): void => {
                this.loading = false;
                if (availableVehicles.length === 0) {
                    confirmOk(`No vehicle/target image information is available for ${this.selectedYear} ${this.selectedMake} ${this.selectedModel}.`);
                    return;
                }
                const vehicle = availableVehicles[0];
                const cals = vehicle.VehicleCalibrations.filter((v: VehicleCalibrationDto, index: number): boolean => {
                    return v.ActiveInd;
                });
                if (cals.length <= 0) {
                    confirmOk(`No vehicle calibration types are available for ${this.selectedYear} ${this.selectedMake} ${this.selectedModel}.`);
                } else if (cals.length === 1) {
                    this.multipleCalibrations = false;
                    this.vehicle = vehicle;
                    this.selectedCalibration = cals[0].CalibrationType;
                } else { // cals.length > 1
                    this.multipleCalibrations = true;
                    this.vehicle = vehicle;
                }
            })
            .catch((reason): void => {
                confirmOk(`Unable to retrieve vehicle/target image for ${this.selectedYear} ${this.selectedMake} ${this.selectedModel}.`);
            });
    }

    cancelClicked(): void {
        App.MainApp.cancelCalibration();
    }

    nextClicked(): void {
        Global.Make = this.selectedMake;
        Global.Model = this.selectedModel;
        Global.Year = Number(this.selectedYear);
        Global.LegLengthA = this.vehicle!.LegLengthA;
        Global.LegLengthB = this.vehicle!.LegLengthB;
        Global.LegLengthC = this.vehicle!.LegLengthC;
        Global.Marker = this.vehicle!.Marker;
        this.sendSelection(this.vehicle!.VehicleId);
        this.loading = true;
    }

    sendSelection(vehicleId: number): void {
        const globals = Global;
        CalibrationEventSupport.sendEventVehicleSelection(CalibrationEvent.VehicleSelectionByCompanion,
            globals.Year, globals.Make, globals.Model, this.selectedCalibration as CalibrationType);
    }

    mounted(): void {
        this.loading = true;
        App.MainApp.connectSession((): void => {
            this.reloadDropdowns();
        });
    }
}

