
import { Options } from "vue-class-component";
import PageBase from "@/shared/components/common/PageBase";
import HeaderMessage from "@/shared/components/common/HeaderMessage.vue";
import { Global } from "@/support/GlobalData";
import App from "@/App.vue";
import Utility from "@/shared/support/Utility";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import UploadPanel from "@/shared/components/common/UploadPanel.vue";
import { UploadType } from "@/shared/enums/UploadType";
import Globals from "@/support/Globals";

@Options({
    components: {
        HeaderMessage,
        UploadPanel,
    },
    watch: {
        $route(to, from): void {
            // going from this page to some page. If it's the same, check query parameters and
            // render target image
            if (from.path.toLowerCase() === to.path.toLowerCase()) { // going to the same page
                const q = to.query;
                this.imageNumber = Number(q.TargetNumber);
                this.totalImages = Number(q.TotalTargets);
                this.targetUploadId = Number(q.TargetUploadId);
                this.renderTargetImage();
            }
        }
    }
})

export default class SendTargets extends PageBase {

    imageUrl = "";
    imageNumber: number = 0;
    totalImages: number = 0;
    targetUploadId: number = 0;

    vehicle(): string {
        return `${Global.Year} ${Global.Make} ${Global.Model}`;
    }

    getUploadType(): number {
        return UploadType.VehicleDocument;
    }
    getVehicleId(): string {
        return Global.VehicleId?.toString() || "";
    }

    renderTargetImage() : void {
        this.loading = true;
        this.imageUrl = Utility.formatUrl(`/Service/TargetRenderer/GetContentById/${this.targetUploadId}`, {}, Globals.GetWebApi(), true);
        const imgElem = this.$refs.targetImage;
        const isLoaded = imgElem.complete && imgElem.naturalHeight !== 0;
        if (isLoaded)
            this.loading = false; // image already loaded
    }

    targetImageLoaded(): void {
        this.loading = false;
    }

    cancelClicked(): void {
        App.MainApp.cancelCalibration();
    }

    previousClicked(): void {
        CalibrationEventSupport.sendEvent(CalibrationEvent.SendPreviousTargetByCompanion);
        this.loading = true;
    }
    successClicked(): void {
        CalibrationEventSupport.sendEvent(CalibrationEvent.SendNextTargetByCompanion);
        this.loading = true;
    }
    failedClicked(): void {
        CalibrationEventSupport.sendEvent(CalibrationEvent.RestartCalibration);
        this.loading = true;
    }

    mounted(): void {
        this.loading = true;
        App.MainApp.connectSession((): void => {
            const q = this.$router.currentRoute.value.query;
            this.imageNumber = Number(q.TargetNumber);
            this.totalImages = Number(q.TotalTargets);
            this.targetUploadId = Number(q.TargetUploadId);
            this.renderTargetImage();
        });
    }
    $refs!: {
        targetImage: HTMLImageElement,
    }
}

